@import "../../scss/functions.scss";
.home {
  .home-list {
    font-size: 22px;
    text-transform: uppercase;
  }

  a {
    font-size: inherit;
    font-weight: inherit;
  }

  ul {
    > li {
      a {
        font-size: 22px;
        font-weight: 700;
      }

      li {
        a {
          font-size: 16px;
          font-weight: 100;
        }
      }
    }
  }
}

.mtd-page {
  header {
    button,
    .btn,
    .button {
      display: none;
    }
  }
}
